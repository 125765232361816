import { useQuery } from "@apollo/client";
import Big from "big.js";
import dayjs from "dayjs";
import { useMemo } from "react";

import {
  type QueryExplanationOfBenefitsInput,
  flexpa__FlexpaImportStatus,
  gql,
  ingestion__IngestionStatus,
} from "@medbillai/graphql-types";

import { errorHandling } from "../../../lib/apollo/utils";
import {
  FLEXPA_LINK_WAIT_TIME_MINUTES,
  WEB_AGENT_INGESTION_WAIT_TIME_MINUTES,
} from "../../insurance/utils";
import { type NormalizedEob } from "./types";
import { parsePeriod } from "./utils";

const getInsuranceLinkStatus = gql(/* GraphQL */ `
  query GetInsuranceLinkStatusQuery {
    me {
      id
      flexpaLinks {
        id
        active
        lastSyncedAt
        createdAt
        endpoint {
          displayName
          slug
        }
        imports(order: { startedAt: { dir: DESC } }, first: 1) {
          id
          status
        }
      }
      webAgentLinks {
        id
        active
        createdAt
        endpoint {
          displayName
          slug
        }
        latestIngestion {
          id
          status
          startedAt
        }
      }
      explanationOfBenefits {
        id
      }
    }
  }
`);

/*
  Fetches the normalized EOB data for the bill screen. It fetches the most
  recent EOBs linked to a user via 'me'. It also fetches the status of the
  Flexpa and WebAgent links to show the user if the data is loading or not.
*/
const getEobsQuery = gql(/* GraphQL */ `
  query GetEobsQuery($input: QueryExplanationOfBenefitsInput) {
    queryExplanationOfBenefits(input: $input) {
      id
      title
      status
      created
      resourceId
      totalsByType {
        memberLiability {
          value
          currency
        }
      }
      decision {
        text
        coding {
          code
        }
      }
      item {
        id
        productOrService {
          text
          coding {
            code
            display
          }
        }
        reviewOutcome {
          decision {
            text
          }
          reason {
            text
          }
        }
      }
      total {
        category {
          coding {
            code
          }
        }
        amount {
          currency
          value
        }
      }
      patient {
        display
      }
      billablePeriod
      provider {
        display
      }
      facility {
        display
      }
      serviceDate
    }
  }
`);

export enum LoadingStatus {
  COMPLETED = "COMPLETED",
  FAILED = "FAILED",
  IN_PROGRESS = "IN_PROGRESS",
}

interface InsuranceLink {
  linkId: string;
  displayName: string | undefined;
  slug: string | undefined;
  status: LoadingStatus;
}

export type InsuranceLinks = InsuranceLink[];

export function useNormalizedEobs({
  initialFilter,
}: {
  initialFilter?: QueryExplanationOfBenefitsInput;
}) {
  const { loading, data, error, refetch } = useQuery(getEobsQuery, {
    context: errorHandling("caller"),
    variables: {
      input: initialFilter,
    },
  });

  const { data: dataLinks, loading: loadingLinks } = useQuery(
    getInsuranceLinkStatus,
    {
      context: errorHandling("caller"),
    },
  );

  const userHasEOBS: boolean = useMemo(() => {
    if (
      dataLinks?.me?.explanationOfBenefits &&
      dataLinks?.me?.explanationOfBenefits.length > 0
    ) {
      return true;
    }
    return false;
  }, [dataLinks?.me?.explanationOfBenefits]);

  const insuranceLinks: InsuranceLinks = useMemo(() => {
    // Logic for status mirrors getFlexpaLinkStatus && getWebLinkStatus
    // do not update unless you update those functions
    const flexpaLinks: InsuranceLink[] =
      (dataLinks?.me?.flexpaLinks &&
        dataLinks?.me?.flexpaLinks
          .filter(link => link.active)
          .map(link => {
            let status = link.imports?.[0]?.status;
            if (!link?.lastSyncedAt) {
              if (
                dayjs().diff(link.createdAt, "minutes") <
                FLEXPA_LINK_WAIT_TIME_MINUTES
              ) {
                status = flexpa__FlexpaImportStatus.IN_PROGRESS;
              } else {
                status = flexpa__FlexpaImportStatus.FAILED;
              }
            }
            return {
              linkId: link.id,
              displayName: link.endpoint.displayName ?? undefined,
              slug: link.endpoint.slug ?? undefined,
              status: status as unknown as LoadingStatus,
            };
          })) ||
      [];

    const webAgentLinks: InsuranceLink[] =
      (dataLinks?.me?.webAgentLinks &&
        dataLinks?.me?.webAgentLinks
          .filter(link => link.active)
          .map(link => {
            let status = link.latestIngestion?.status;
            if (!status) {
              if (
                dayjs().diff(link.createdAt, "minutes") >
                WEB_AGENT_INGESTION_WAIT_TIME_MINUTES
              ) {
                status = ingestion__IngestionStatus.FAILED;
              } else {
                status = ingestion__IngestionStatus.IN_PROGRESS;
              }
            }
            return {
              linkId: link.id,
              displayName: link.endpoint.displayName ?? undefined,
              slug: link.endpoint.slug ?? undefined,
              status: status as unknown as LoadingStatus,
            };
          })) ||
      [];
    return [...flexpaLinks, ...webAgentLinks];
  }, [dataLinks?.me?.flexpaLinks, dataLinks?.me?.webAgentLinks]);

  // Normalize data
  const normalizedData = useMemo(() => {
    const eobArray = data?.queryExplanationOfBenefits;
    if (eobArray && eobArray.length > 0) {
      return eobArray.map(eob => {
        const memberLiability =
          eob.totalsByType?.memberLiability ??
          // TODO: Remove if totalsByType is reliably present
          eob.total?.find(
            t => t.category?.coding?.[0]?.code === "memberliability",
          )?.amount ??
          undefined;

        const totalDescription = eob.item?.reduce((acc, item) => {
          if (item?.productOrService?.text) {
            return acc + item.productOrService.text + ", ";
          }
          return acc;
        }, "");

        const billablePeriod = parsePeriod(eob.billablePeriod);

        const serviceDate =
          eob.serviceDate ?? billablePeriod?.lower ?? eob.created;

        const nmData: NormalizedEob = {
          id: eob.id,
          resourceId: eob.resourceId,
          name: eob.patient?.display || null,
          status: eob.status,
          decision: eob?.decision?.coding?.[0]?.code || null,
          totalCost: memberLiability
            ? {
                amount: Big(memberLiability.value ?? "0"),
                currency: memberLiability?.currency ?? "USD",
              }
            : undefined,
          location:
            eob?.provider?.display || eob.facility?.display || undefined,
          title: eob.title || undefined,
          serviceDate: serviceDate ? dayjs(serviceDate).toDate() : undefined,
          description: totalDescription || "No summary available",
        };
        return nmData;
      });
    }

    return [];
  }, [data]);

  // Control loading state
  const isLoading = loading || data === null || loadingLinks;

  // Return the normalized data along with the loading and error states
  return {
    loading: isLoading,
    data: normalizedData,
    dataInsuranceLinks: insuranceLinks,
    userHasEOBS,
    error,
    refetch,
  };
}

// location
