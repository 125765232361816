import Big from "big.js";

import {
  type ColorTokens,
  type PillProps,
  type SelectBarTabs,
} from "@medbillai/ui";

import {
  type BillTotals,
  type EOB,
  type EOBTotals,
  type EOBTotalsByType,
  type Money,
  type Period,
} from "./types";

export const parsePeriod = (periodStr: string | null | undefined) => {
  if (!periodStr) {
    return;
  }
  return JSON.parse(JSON.stringify(periodStr)) as Period;
};

// Used when totalsByType is not available.
export function parseTotals(totals: EOBTotals): BillTotals {
  // TODO: In theory, we should always be setting totalsByType. Therefore, we
  // consider hitting this case to be a bug. We should remove this function if
  // we do not see it being used.
  console.error("Bill page hit EOB without totalsByType");

  const result: BillTotals = {};
  for (const total of totals) {
    if (total.amount.value) {
      // It's possible for this to actually insert keys that are not in
      // EOBTotalsByType, but we don't care, since we always pull out values by
      // a specific key.
      result[total.category.coding?.[0]?.code as keyof EOBTotalsByType] = {
        amount: Big(total.amount.value),
        currency: total.amount.currency ?? "USD",
      };
    }
  }
  return result;
}

export function parseTotalsByType(totalsByType: EOBTotalsByType): BillTotals {
  const result: BillTotals = {};
  Object.entries(totalsByType).forEach(([key, value]) => {
    if (value?.value) {
      result[key as keyof EOBTotalsByType] = {
        amount: Big(value.value),
        currency: value.currency ?? "USD",
      };
    }
  });
  return result;
}

export function parseBillTotals(
  totalsByType: EOB["totalsByType"],
  totals: EOB["total"],
): BillTotals | null {
  // TODO: Clean up once we are confident we do not need to handle missing
  // totalsByType.
  if (totalsByType) {
    return parseTotalsByType(totalsByType);
  }
  if (totals) {
    return parseTotals(totals);
  }
  return null;
}

interface TextStatusProps {
  text: string;
  textColor: ColorTokens;
}

export const getTextStyle = (
  status: string | undefined | null,
): TextStatusProps | undefined => {
  if (!status) {
    return undefined;
  }
  switch (status) {
    case "in":
      return {
        text: "In Network",
        textColor: "$green11",
      };
    case "out":
      return {
        text: "Out Of Network",
        textColor: "$color12",
      };
    case "approved":
      return {
        text: "Approved",
        textColor: "$green11",
      };
    case "denied":
      return {
        text: "Denied",
        textColor: "$red11",
      };
    case "partial":
      return {
        text: "Partial",
        textColor: "$yellow11",
      };
    default:
      return {
        text: "Unknown",
        textColor: "$color12",
      };
  }
};

export const getPillStyle = (status: string | undefined | null): PillProps => {
  switch (status) {
    case "in":
      return {
        text: "In Network",
        notificationDot: false,
        theme: "alt2",
        textColor: "$color12",
      };
    case "out":
      return {
        text: "Out Of Network",
        notificationDot: false,
        theme: "alt2",
        textColor: "$color12",
      };
    case "approved":
      return {
        text: "Approved",
        notificationDot: false,
        theme: "green",
        textColor: "$green11",
      };
    case "denied":
      return {
        text: "Denied",
        notificationDot: false,
        theme: "red",
        textColor: "$red11",
      };
    case "partial":
      return {
        text: "Denied",
        notificationDot: false,
        theme: "yellow",
        textColor: "$yellow11",
      };
    default:
      return {
        text: "Unkown",
        notificationDot: false,
        theme: "alt2",
        textColor: "$color12",
      };
  }
};

export function formatCostTotal(item?: Money, empty = "-", inverse?: boolean) {
  if (!item || !item.amount) {
    return empty;
  }

  const roundedAmount = item.amount.round(0, Big.roundDown).abs();
  let negative = item.amount.lt(0);
  if (inverse) {
    negative = !negative;
  }

  const formattedNumber = new Intl.NumberFormat().format(
    roundedAmount.toNumber(),
  );

  // TODO: Handle currency
  return `${negative ? "-" : ""}$${formattedNumber}`;
}

export const allFilter = {
  input: {
    all: true,
  },
};

export const deniedFilter = {
  input: {
    denied: true,
  },
};

export const inNetworkFilter = {
  input: {
    inNetwork: true,
  },
};

export const outOfNetworkFilter = {
  input: {
    outOfNetwork: true,
  },
};

export const filterOptionIds = {
  all: "all",
  denied: "denied",
  inNetwork: "in-network",
  outOfNetwork: "out-of-network",
};

export const filterOptions: SelectBarTabs = [
  { id: filterOptionIds.all, name: "All" },
  { id: filterOptionIds.denied, name: "Denied" },
  { id: filterOptionIds.inNetwork, name: "In network" },
  { id: filterOptionIds.outOfNetwork, name: "Out of network" },
];
