import React from "react";

import { Check as CheckIcon, X as XIcon } from "@tamagui/lucide-icons";

import {
  Circle,
  Ellipsis as EllipsisIcon,
  type ParagraphProps,
  type XStackProps,
} from "@medbillai/ui";

interface statusCircleProps {
  status: string | null;
  wrapperProps?: XStackProps;
  textProps?: ParagraphProps;
}

const getCircleIcon = (status: string | null) => {
  switch (status) {
    case "approved":
      return <CheckIcon color="$color10" strokeWidth={1} />;
    case "denied":
      return <XIcon color="$color10" strokeWidth={1} />;
    case "pending":
      return <EllipsisIcon color="$color6" strokeWidth={1} />;
    default:
      return <EllipsisIcon color="$color6" strokeWidth={1} />;
  }
};

const getTheme = (status: string | null) => {
  switch (status) {
    case "approved":
      return "green";
    case "denied":
      return "red";
    case "pending":
      return "blue";
    default:
      return "blue";
  }
};

export function StatusCircle({ status, wrapperProps }: statusCircleProps) {
  return (
    <Circle
      theme={getTheme(status)}
      alignItems="center"
      jc="center"
      p="$2.5"
      bg="$color3"
      {...wrapperProps}
    >
      {getCircleIcon(status)}
    </Circle>
  );
}
