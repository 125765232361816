import React from "react";
import { useLink } from "solito/link";

import { ChevronRight } from "@tamagui/lucide-icons";

import { DateText, Paragraph, XStack, YStack, styled } from "@medbillai/ui";

import { billUrl } from "@medbillai/utils";

import { type NormalizedEob } from "../utils/types";
import { formatCostTotal } from "../utils/utils";
import { StatusCircle } from "./StatusCircle";

type BillItemProps = {
  eob: NormalizedEob;
  homePage?: boolean;
};

/**
 * These Functions are all to help put together data in the future when we have
 * invoice & eob data to work with, they are simple now.
 * TODO: move to utils if more complex and used in multiple places
 */

const DateTextStyle = styled(Paragraph, {
  size: "$2",
  color: "$inputTextColorPrimary",
  fontWeight: "500",
  whiteSpace: "nowrap",
  textAlign: "left",
});

const TitleParagraph = styled(Paragraph, {
  size: "$3",
  color: "$softBlackPrimary",
  fontWeight: "500",
  overflow: "hidden",
  numberOfLines: 1,
});

const SubtitleParagraph = styled(Paragraph, {
  size: "$2",
  color: "$softBlackPrimary",
  fontWeight: "400",
  overflow: "hidden",
  numberOfLines: 1,
});

const BillListItem = ({ eob, homePage }: BillItemProps) => {
  return (
    <XStack
      mx="$4"
      f={1}
      borderBottomWidth={homePage ? 0 : 0.25}
      borderBottomColor="$color6"
    >
      <XStack
        {...useLink({
          href: billUrl(
            eob.resourceId,
            eob.location || "Unknown Location",
            eob.decision || "",
          ),
        })}
        cur="pointer"
        bg="$color1"
        py="$2"
        gap="$3"
        my="$2"
        br="$4"
        justifyContent="space-between"
        pressStyle={{
          backgroundColor: "#F2F5FF80",
        }}
        f={1}
      >
        <XStack ai="center" jc="flex-start" gap="$3" flex={1} paddingLeft="$1">
          <StatusCircle status={eob.decision} />
          <YStack alignItems="flex-start" justifyContent="center" flex={1}>
            {eob.title ? (
              <>
                <TitleParagraph>{eob.title}</TitleParagraph>
                {eob.location && !homePage && (
                  <SubtitleParagraph>{eob.location}</SubtitleParagraph>
                )}
              </>
            ) : (
              <TitleParagraph>{eob.location ?? "-"}</TitleParagraph>
            )}

            <DateText
              date={eob.serviceDate}
              format="Do MMMM YYYY"
              element={DateTextStyle}
              utc={true}
            />
          </YStack>
        </XStack>
        <XStack gap="$2" ai="center" jc="space-between" flexShrink={0}>
          <Paragraph fow="600" size="$5" color="$softBlackPrimary">
            {formatCostTotal(eob.totalCost, "$0")}
          </Paragraph>
          <ChevronRight color="$inputTextColorPrimary" />
        </XStack>
      </XStack>
    </XStack>
  );
};

type EobListProps = {
  eobs: NormalizedEob[];
  homePage?: boolean;
};

export const BillList = ({ eobs, homePage }: EobListProps) => {
  return (
    <YStack>
      {eobs.map(eob => (
        <BillListItem key={eob.id} eob={eob} homePage={homePage} />
      ))}
    </YStack>
  );
};
